$text-color: #2b2b2b;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  color: $text-color;
}

.layout {
  width: 60%;
  margin: 0 auto;
}

h1 {
  font-size: 2rem;
}

#loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );

  .message {
    position: relative;
    top: 50%;
    font-size: 2rem;
    text-align: center;
  }
}

.hide {
  display: none;
}

.errorMsg {
  @extend .layout;
  color: #f21e08;
  background: #fff0f0;
  padding: 1rem;
  margin-top: 1rem;
}

.header {
  width: 90%;
  margin: 1rem auto 2rem auto;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  font-size: 1.1rem;
}

.header-links .link:link,
.header-links .link:visited {
  color: $text-color;
  padding: 1rem;
}

.bookmark-search {
  @extend .layout;

  .searchTerm {
    height: 3rem;
    border-radius: 1rem;
  }
}

.filters-list {
  @extend .layout;

  .filters {
    display: flex;
    justify-content: center;
    margin: 0 1rem;

    & > div {
      padding: 6px;
      margin: 0 5px;
      background-color: #90d4a7;
      color: #22543d;
      border-radius: 12px;
      font-size: 0.8rem;
      font-weight: 400;
      text-transform: uppercase;
      cursor: pointer;

      &.active {
        font-weight: 800;
      }
    }
  }
}

.bookmark-form {
  width: 90%;
  margin: auto;
}

.edit-btn,
.delete-btn {
  cursor: pointer;
}

.loading {
  font-size: 2rem;
  text-align: center;
  font-size: 2rem;
}

.bookmarks-list {
  margin: 2rem 0;
}

.no-result {
  text-align: center;
  margin: 1rem 0;
}

.bookmark {
  display: flex;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  // background: #65bbc9;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  justify-content: space-between;
  transition: all 0.5s;

  & > div {
    margin: 1rem 0;
  }

  & > div:first-child {
    flex: 2;
  }

  .buttons {
    text-align: right;
  }
}

@media screen and (max-width: 600px) {
  .layout {
    width: 90%;
    margin: 0 auto;
  }

  .header {
    flex-direction: column;
    margin-bottom: 1rem;

    h1 {
      font-size: 1.8rem;
      text-align: center;
    }
  }

  .header-links {
    margin: 1rem 0;

    & .link:link,
    & .link:visited {
      padding: 0.5rem;
      display: block;
      text-align: center;
    }
  }

  .filters-list {
    .filters {
      flex-wrap: wrap;

      & > div {
        margin: 5px;
      }
    }
  }

  .bookmark {
    flex-direction: column;

    & > div {
      word-break: break-all;
    }
    .buttons {
      text-align: left;
    }
  }
}